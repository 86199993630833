import React from 'react';
import map from '../images/icon_map.png';
import letter from '../images/icon_mail.png';

const Footer = () => (
	<div className="footer" id="contactus">
		<h2>Contact</h2>
		<div>
			<div>
				<img src={map} className="footericon" alt="Our address" />
				<p>
					SURF Companies
					<br />
					Capital Tower #12-01
					<br />
					168 Robinson Road
					<br />
					Singapore 068912
				</p>
			</div>
			<div>
				<img src={letter} className="footericon" alt="Our email address" />
				<p>
					<a href="mailto:impact@surf.capital">impact@surf.capital</a>
				</p>
			</div>
		</div>
	</div>
);

export default Footer;
