import React from 'react';
import { Link } from 'gatsby';
import { CSSTransition } from 'react-transition-group';

// <CSSTransition
// 					in={this.state.modalOpen}
// 					timeout={{
// 						enter: 0,
// 						exit: 1000
// 					}}
// 					classNames="modalheader"
// 					onExited={this.finishTransition}
// 				>
// 					<div className="modalheader" onClick={this.state.modalOpen ? this.turnOffModal : null}>
// 						<div className="headerleft">
// 							<Link to="/">
// 								<img className="logo" src={logo} alt="Sosimple. Cheap energy. Clean business." />
// 							</Link>
// 							<div className="headerlink">
// 								<span className="closemenu" onClick={this.turnOffModal}>
// 									Close
// 								</span>
// 							</div>
// 						</div>
// 						<div className="headerright">
// 							<Link to="/#howdoesitwork" className="headerlink scrolllink">
// 								How it works
// 							</Link>
// 							<Link to="/whoweare/" className="headerlink">
// 								Who we are
// 							</Link>
// 							<ContactUsNowButton id="modalcontactus" hidden={!this.props.headerButtonVisible} />
// 						</div>
// 					</div>
// 				</CSSTransition>

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.toggleMobile = this.toggleMobile.bind(this);
		this.closeMobile = this.closeMobile.bind(this);
		this.state = { mobileMenu: false };
	}
	toggleMobile() {
		let flipped = !this.state.mobileMenu;
		this.setState({ mobileMenu: flipped });
	}
	closeMobile() {
		this.setState({ mobileMenu: false });
	}
	render() {
		return (
			<div className={`header ${this.props.transparent ? 'transparent' : ''}`}>
				<Link to="/">
					<div className="logo" alt="Surf Capital" />
				</Link>
				<div className="headerlinks" onClick={this.closeMobile}>
					<span>
						<Link to="/#themes" onClick={this.closeMobile}>
							Themes
						</Link>
					</span>
					<span>
						<Link to="/#investments" onClick={this.closeMobile}>
							Our Investments
						</Link>
					</span>
					<span>
						<Link to="/#contactus" onClick={this.closeMobile}>
							Contact
						</Link>
					</span>
					<span className="right">
						<Link to="/login/" onClick={this.closeMobile}>
							<span className="ti-lock" />
							Investor Login
						</Link>
					</span>
				</div>
				<div className={`mobiletoggle ${this.state.mobileMenu ? 'open' : ''}`} onClick={this.toggleMobile} />
				<CSSTransition
					in={this.state.mobileMenu}
					timeout={{
						enter: 0,
						exit: 0
					}}
					classNames="mobilemenu"
				>
					<div className="mobilemenu">
						<div className="mobilescrim" onClick={this.closeMobile} />
						<div className="headerlinks" onClick={this.closeMobile}>
							<span>
								<Link to="/#themes" onClick={this.closeMobile}>
									Themes
								</Link>
							</span>
							<span>
								<Link to="/#investments" onClick={this.closeMobile}>
									Our Investments
								</Link>
							</span>
							<span>
								<Link to="/#contactus" onClick={this.closeMobile}>
									Contact
								</Link>
							</span>
							<span>
							<Link to="/login/" onClick={this.closeMobile}>
								<span className="ti-lock" />
								Investor Login
							</Link>
						</span>
							</div>
					</div>
				</CSSTransition>
			</div>
		);
	}
}

export default Header;
