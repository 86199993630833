import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Observer from 'react-intersection-observer';

import Header from './header';
import Footer from './footer';
import '../scss/styles.scss';

class Layout extends React.Component {
	render() {
		return (
			<StaticQuery
				query={graphql`
					query SiteTitleQuery {
						site {
							siteMetadata {
								title
							}
						}
					}
				`}
				render={data => (
					<>
						<Helmet
							title={data.site.siteMetadata.title}
							meta={[{ name: 'description', content: 'Surf Capital' }, { name: 'keywords', content: 'Surf Capital' }]}
						>
							<html lang="en" />
						</Helmet>
						<Observer>
							{({ inView, ref }) => (
								<div ref={ref}>
									<Header transparent={inView} />
								</div>
							)}
						</Observer>

						<div className="main" id={this.props.id ? this.props.id : ''}>
							{this.props.children}
							<div className="bottomspacer">&nbsp;</div>
						</div>
						<Footer />
					</>
				)}
			/>
		);
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
